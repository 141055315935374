@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik");
@font-face {
  font-family: 'PDU';
  src: url("webfont.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/pdu-regular-web-new/pdu-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/pdu-regular-web/pdu-regular.woff2") format("woff2"), url("../fonts/pdu-regular-web/pdu-regular.woff") format("woff"), url("../fonts/pdu-regular-web/pdu-regular.ttf") format("truetype"), url("../fonts/pdu-regular-web/pdu-regular.svg#svgFontName") format("svg");
  /* Legacy iOS */
}

@font-face {
  font-family: 'PDU-euro';
  src: url("PDU-Kraak.eot");
  src: url("../fonts/pdu-regular-pattern-web-new/PDU-Kraak.eot?#iefix") format("embedded-opentype"), url("../fonts/pdu-regular-pattern-web-new/PDU-Kraak.woff2") format("woff2"), url("../fonts/pdu-regular-pattern-web-new/PDU-Kraak.woff") format("woff"), url("../fonts/pdu-regular-pattern-web-new/PDU-Kraak.ttf") format("truetype"), url("../fonts/pdu-regular-pattern-web-new/PDU-Kraak.svg#PDU-Kraak") format("svg");
  font-weight: normal;
  font-style: normal;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  z-index: 990;
  background: transparent;
  padding: 15px 0px 12px 7px;
  font-weight: 400;
  background: #FFFFFA;
  font-family: PDU, sans-serif;
  /* max-height: 100px; */
}

.navigation a {
  text-decoration: none;
}

.nav-list {
  display: none;
}

/* ------- COLOR EXCEPTIONS --------- */
.homepage .navigation,
.concerts .navigation {
  background: #000;
  color: rgba(255, 255, 255, 0) !important;
}

.homepage .navigation nav:hover,
.concerts .navigation nav:hover {
  color: #FFFFFA;
}

.homepage .navigation a,
.concerts .navigation a {
  color: #FFFFFA !important;
}

.concerts .navigation .overlay-filter {
  color: #00FFFF !important;
}

.records .navigation .overlay-filter {
  color: magenta !important;
}

.avant-guardian .navigation .overlay-filter {
  color: #470b47 !important;
}

.concerts .navigation a:hover {
  color: #00FFFF !important;
}

.records .navigation a:hover {
  color: magenta !important;
}

.avant-guardian .navigation a:hover {
  color: #470b47 !important;
}

.homepage #nav-toggle span, .homepage #nav-toggle span:before, .homepage #nav-toggle span:after,
.concerts #nav-toggle span, .concerts #nav-toggle span:before, .concerts #nav-toggle span:after {
  background: #FFFFFA;
}

.homepage #nav-toggle.active span,
.concerts #nav-toggle.active span {
  background: transparent;
}

.records .navigation .overlay-filter-close,
.records .navigation .overlay-filter-close:hover {
  color: #FFFFFA !important;
  text-decoration: underline;
}

.concerts .navigation .overlay-filter-close,
.concerts .navigation .overlay-filter-close:hover {
  color: #000 !important;
  text-decoration: underline;
}

.avant-guardian .navigation .overlay-filter-close,
.avant-guardian .navigation .overlay-filter-close:hover {
  color: #FFFFFA !important;
  text-decoration: underline;
}

body.overlay-active.concerts .navigation a.active {
  color: #000 !important;
}

/* -------  EXCEPTIONS WITH OVERLAY --------- */
body.overlay-active .navigation {
  z-index: 991;
  background: transparent !important;
}

body.overlay-active .navigation a, body.overlay-active .navigation .brand-logo-svg, body.overlay-active .navigation .sentence {
  opacity: 0;
}

body.overlay-active .navigation a.active {
  display: inline;
  opacity: 1;
}

.homepage .navigation {
  display: none;
}

.brand {
  position: absolute;
  padding-left: 0px;
  float: left;
  line-height: 85px;
  text-transform: uppercase;
  font-size: 1.4em;
}

.brand a,
.brand a:visited {
  color: #000;
  text-decoration: none;
}

nav {
  float: left;
  width: calc(100% - 80px);
  font-size: 2.6vw;
  line-height: 2.7vw;
  color: transparent;
}

nav:hover {
  color: black;
}

nav .nav-list-sentence {
  width: 80%;
  float: left;
  padding-left: 3px;
}

nav .nav-list-sentence a,
nav .nav-list-sentence a:visited {
  color: #000;
}

nav .nav-list-sentence a:hover,
nav .nav-list-sentence a:visited:hover {
  color: #000;
  text-decoration: underline;
}

nav .nav-list-sentence a.active {
  color: #000;
  text-decoration: underline;
  position: relative;
  z-index: 999;
}

nav .nav-list-browse-word {
  float: left;
  /* width: 140px; */
  margin-left: 0px;
}

nav .overlay-filter:hover {
  text-decoration: underline;
}

nav ul.nav-list {
  display: none;
  width: auto;
  padding: 0;
  margin: 0 auto;
}

nav ul.nav-list li {
  width: 100%;
  display: block;
  text-align: center;
  border-bottom: 1px solid #000;
  float: left;
}

nav ul.nav-list li a {
  padding: 5px 0;
  line-height: 20px;
}

nav ul.nav-list li a:hover {
  text-decoration: none;
}

nav ul.nav-list li.noline {
  border-bottom: 0px solid #000;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  position: relative;
  text-align: right;
  font-size: 32px;
  line-height: 30px;
  padding: 3px 5px;
}

nav ul li a,
nav ul li a:visited {
  color: #000;
  text-decoration: none;
}

nav ul li a:hover,
nav ul li a:visited:hover {
  color: #000;
  text-decoration: underline;
}

nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
  padding-left: 4px;
  content: ' ▾';
}

nav ul li ul li {
  min-width: 190px;
}

nav ul li ul li a {
  padding: 15px;
  line-height: 20px;
}

.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  /* not needed background: $nav-background-color; */
  height: 60px;
  width: 60px;
}

/* RESPONSIVE ------------*/
/* in settings: $nav-font-size: 32px;
$nav-font-size-tablet: 28px;
$nav-font-size-wide: 38px;

$nav-medium-line-height: 36px;
$nav-line-height-size-tablet: 36px;
$nav-line-height-size-wide: 36px; */
@media only screen and (max-width: 860px) {
  .navigation {
    min-height: 80px;
    padding-bottom: 5px;
  }
  .navigation .medium-12.columns {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 10px;
  }
  .concerts ul.nav-list li {
    border-color: #FFFFFA;
  }
  .mobile-clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .nav-mobile {
    display: block;
  }
  nav {
    font-size: 6.0vw;
    line-height: 6.2vw;
    width: calc(100% - 160px);
    margin-left: 85px;
    text-align: center;
    /* krakie weird layout */
    /* 

    /* default mob stuff */
  }
  nav .nav-list-sentence {
    display: none;
  }
  nav .hide-on-mobile {
    display: none;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: left;
    padding: 0;
  }
  nav ul li a {
    padding: 5px 0;
    display: block;
    line-height: 20px;
  }
  nav ul li ul li a {
    padding-left: 30px;
  }
  .nav-dropdown {
    position: static;
  }
  .brand-logo-svg {
    float: left;
    position: absolute;
    top: 0;
    left: 10px;
  }
}

/* iphone 5 */
@media handheld, only screen and (max-width: 860px) {
  nav ul li a, nav ul li a:visited {
    font-size: 18px;
  }
}

/* tablet view */
@media handheld, only screen and (min-width: 860px) and (max-width: 1080px) {
  nav {
    font-size: 2.4vw;
    line-height: 2.5vw;
  }
}

@media handheld, only screen and (min-width: 1081px) and (max-width: 1180px) {
  nav {
    font-size: 2.5vw;
    line-height: 2.6vw;
  }
}

/* ultru wide view */
@media handheld, only screen and (min-width: 1350px) {
  nav {
    float: left;
    font-size: 2.6vw;
    line-height: 2.7vw;
  }
}

@media screen and (min-width: 860px) {
  .nav-list {
    display: none;
  }
}

#nav-toggle {
  position: absolute;
  left: -18px;
  top: 5px;
  cursor: pointer;
  padding: 30px 55px 26px 10px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 35px;
  background: #000;
  /* the stripes */
  position: absolute;
  display: block;
  content: '';
  transition: all 300ms ease-in-out;
}

#nav-toggle span:before {
  top: -10px;
}

#nav-toggle span:after {
  bottom: -10px;
}

#nav-toggle.active span {
  background-color: transparent;
}

#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}

#nav-toggle.active span:before {
  transform: rotate(45deg);
}

#nav-toggle.active span:after {
  transform: rotate(-45deg);
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 17px;
  line-height: 22px;
  background: #FFFFFA;
  font-family: Helvetica, sans-serif;
  padding-left: 0px;
  padding-right: 0px;
}

body.overlay-active {
  overflow: hidden;
}

/* sticky footer */
.wrapper {
  min-height: 100%;
  margin: 0 auto -249px;
}

footer, .push {
  clear: both;
  min-height: 249px;
}

.push {
  content: "";
  display: block;
  clear: both;
}

h1 {
  font-size: 100px;
  font-family: PDU, sans-serif;
  line-height: 110px;
}

.big,
h2 {
  font-size: 42px;
  font-family: PDU, sans-serif;
  line-height: 50px;
  text-transform: uppercase;
  color: #000;
}

h3 {
  font-size: 32px;
  line-height: 33px;
}

h4 {
  font-size: 24px;
  line-height: 26px;
}

.big a, h2 a {
  color: #000;
}

.big a:hover, h2 a:hover {
  text-decoration: underline;
}

/* eg subtitles avant guardian or schedule concerts*/
h4 {
  padding-left: 40px;
}

/* eg avant guardian names interviewer */
h5 {
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  margin: 30px 0 0 0;
  line-height: 15px;
}

.small {
  font-size: 12px;
  line-height: 15px;
}

.euro-sign {
  font-family: 'PDU-euro';
}

.button {
  border: 3px solid #00FFFF;
  padding: 5px 10px;
  background: transparent;
  color: #00FFFF;
  font-family: PDU, sans-serif;
  font-size: 32px;
  transition-duration: 0;
}

.button:hover {
  border-color: #FFFFFA;
  color: #FFFFFA;
  background: transparent;
}

.records .button {
  border-color: magenta;
}

.records .button:hover, .records .button:focus {
  border-color: magenta;
  color: #FFFFFA;
  background: magenta;
}

table {
  background: transparent;
  border: 0;
}

table tbody,
table tr,
table tr:nth-child(even) {
  background: transparent;
  border: 0;
}

table td {
  padding: 0 2px;
}

.split {
  width: 50%;
  float: left;
  padding-right: 15px;
}

.padding-indent {
  padding-left: 80px;
  padding-right: 80px;
}

.padding-indent-half {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-indent-half.padding-indent-half-keep-on-mobile {
  padding-left: 40px;
  padding-right: 40px;
}

.image-container img {
  width: 100%;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* grid light up
.row {
  border:1px solid yellow;
}
.content {
  border:1px solid blue;
}
*/
.content {
  padding: 105px 10px 20px 10px;
}

.homepage .content {
  padding: 0 10px 20px 10px;
}

.block {
  padding: 0px;
}

.block.inside-page {
  padding: 25px 10px 20px 10px;
}

/* no padding */
.no-padding {
  padding: 0;
}

/* basic padding */
.basic-padding-top {
  padding-top: 35px;
}

.basic-padding-bottom {
  padding-bottom: 35px;
}

/*  large */
.extra-padding-top {
  padding-top: 70px;
}

.extra-padding-bottom {
  padding-bottom: 70px;
}

.band-info ul li,
.ag-article ul li,
.body-text ul li {
  list-style-type: none;
}

.band-info ul li:before,
.ag-article ul li:before,
.body-text ul li:before {
  content: "- ";
}

.icon {
  height: 0;
  content: ' ';
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  width: 60px;
  padding-top: 60px;
  background-size: 60px 60px;
  background-image: url(../images/icons/logo-color-transparent.svg);
  margin: 0;
}

.headerpic {
  clear: both;
  background: transparent center center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  min-height: 600px;
  margin-bottom: 10px;
  z-index: 100;
}

.headerpic .headerpic-block,
.headerpic-special-block {
  position: absolute;
  text-align: center;
  font-size: 25px;
  height: 100px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: bottom;
}

.headerpic-special-block {
  z-index: 919;
}

.headerpic-special-block h2 {
  color: #FFF;
}

.headerpic .headerpic-block h2 {
  font-family: PDU, sans-serif;
  font-size: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFA;
}

.brand-logo-svg {
  height: 0;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  background-image: url(../images/common/logo-color-transparent.svg);
  width: 70px;
  padding-top: 70px;
  background-size: 70px 70px;
  margin: -2px 0 0 0;
  float: right;
}

.homepage .brand-logo-svg,
.concerts .brand-logo-svg {
  background-image: url(../images/common/logo-color-black-bg.svg);
}

.fixednews {
  border-bottom: 1px solid rgba(255, 255, 250, 0.3);
  padding: 15px 0 30px 0;
  margin-bottom: 25px;
  text-align: center;
}

.fixednews a {
  text-decoration: underline;
}

footer {
  padding: 20px 0 20px 0;
  background-color: #000;
  color: #FFFFFA;
  font-size: 15px;
  line-height: 19px;
  border-top: 1px solid rgba(255, 255, 250, 0.3);
  clear: both;
  margin-top: 20px;
  min-height: 229px;
}

footer h4 {
  padding-left: 0;
}

footer a {
  color: #FFFFFA !important;
}

footer input,
footer .button {
  border-color: #FFFFFA;
  color: #FFFFFA;
  margin-bottom: 0;
}

footer input[type="text"], footer [type="text"]:focus {
  width: 100%;
  position: relative;
  top: 4px;
  background-color: #000;
}

footer .button, footer .button:hover, footer .button:focus {
  position: relative;
  top: -3px;
  width: auto;
  border-color: #FFFFFA !important;
}

footer img.partner {
  width: 35%;
  margin-right: 2%;
  display: inline-block;
  margin-top: 5px;
}

footer.home {
  background: #FFFFFA;
  color: #000;
  min-height: 100px;
  text-align: left;
  margin-top: 50px;
}

footer.home a {
  color: #000;
  text-decoration: underline;
}

footer.home h4,
footer.home h4 a {
  color: #000 !important;
}

.masonry-sizer,
.masonry-item {
  width: 33%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 25px;
}

/* 4 columns */
.masonry-four-col .masonry-sizer,
.masonry-four-col .masonry-item {
  width: 25%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 50px;
}

.masonry-four-col .masonry-item-double {
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
}

.avant-guardian .masonry {
  padding-top: 25px;
}

.avant-guardian .masonry-sizer,
.avant-guardian .masonry-item {
  width: 33%;
  padding-right: 35px;
  padding-left: 35px;
  padding-bottom: 50px;
}

.band-listing .masonry-sizer,
.band-listing .masonry-item {
  width: 50%;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 40px;
}

/* The Overlay (background) */
.overlay {
  height: 0%;
  /* put on 0% to slide it open */
  width: 100%;
  position: fixed;
  z-index: 991;
  left: 0;
  top: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  /* special colors overlay */
  /* Position the close button (top right corner) */
}

.overlay.concerts {
  background-color: rgba(0, 255, 255, 0.9);
}

.overlay.concerts a {
  color: #000;
}

.overlay.records {
  background-color: rgba(255, 0, 255, 0.9);
}

.overlay.records a {
  color: #FFFFFA;
}

.overlay.records .title {
  color: #000;
}

.overlay.avant-guardian {
  background-color: rgba(71, 11, 71, 0.9);
}

.overlay.avant-guardian a {
  color: #FFFFFA;
}

.overlay.avant-guardian .title {
  color: #b4b4b4;
}

.overlay .count {
  display: inline-block;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  vertical-align: top;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: -4px;
}

.overlay .content {
  font-family: PDU, sans-serif;
  font-size: 32px;
  line-height: 32px;
  color: #FFFFFA;
}

.overlay .title {
  font-family: PDU, sans-serif;
  font-size: 32px;
  line-height: 32px;
  color: #FFFFFA;
  text-decoration: underline;
  margin-bottom: 5px;
}

.overlay .closebtn {
  position: absolute;
  top: 40px;
  right: 55px;
  font-size: 60px;
  color: #FFFFFA;
}

.overlay .closebtn:hover {
  text-decoration: none;
}

.overlay a:hover {
  text-decoration: underline;
}

.related {
  position: relative;
  background: #000;
  color: #FFFFFA;
  margin: 0;
  padding: 0 !important;
  /* hack for the border line equal width */
  /* avant-guardian */
  /* concerts */
  /* ---- white version ------ */
  /* degradee effect */
}

.related .related-title {
  cursor: pointer;
}

.related .related-title h2 {
  font-size: 42px;
  line-height: 40px;
  color: #FFFFFA;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: -15px;
  margin-left: 17px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.related.white-bg .related-title h2 {
  color: #000;
}

.related.white-bg .related-title.js-close-content h2,
.related.white-bg .related-title:hover h2 {
  color: #000;
}

.related .row {
  padding: 25px 0;
  margin-top: 50px;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.related .row.related-content {
  margin-top: 0px;
  padding-top: 0px;
}

.related .column, .related .columns {
  padding-left: 0;
  padding-right: 35px;
}

.related .column.last, .related .columns.last {
  padding-left: 0;
  padding-right: 0;
}

.related .top-line {
  border-top: 1px solid #00FFFF;
}

.related .big a, .related h3 a {
  color: #FFFFFA;
}

.related .concerts h3 {
  margin-top: 0;
}

.related h5 {
  padding-left: 40px !important;
  margin: 0 10px 15px 0;
}

.related .records h3 {
  margin-top: 6px;
  margin-bottom: 10px;
}

.related .records h5 {
  color: magenta;
}

.related .concerts h5 {
  color: #00FFFF;
}

.related h5.ag {
  margin-bottom: 17px;
  /* not clean */
  color: #470b47;
}

.related .avant-guardian .square,
.related .avant-guardian .big:before {
  background: #FFFFFA;
}

.related .avant-guardian .intro,
.related .avant-guardian .big,
.related .avant-guardian .date,
.related .avant-guardian .author {
  color: #FFFFFA;
}

.related .item {
  margin-bottom: 25px;
}

.related .avant-guardian .intro {
  margin-bottom: 0;
  padding-bottom: 7px;
  width: 100%;
}

.related .avant-guardian .item {
  position: relative;
  top: -5px;
}

.related .avant-guardian .square {
  top: 5px;
}

.related .concerts {
  background: #000;
}

.related .concerts .image-container {
  margin-bottom: 7px;
}

.related .concerts a .date, .related .concerts .date {
  padding-left: 0;
}

.related .concerts .date-location {
  padding-left: 40px;
}

.related .concerts .item {
  position: relative;
  top: -2px;
  margin-bottom: 30px;
}

.related.white-bg {
  background: #FFFFFA;
  color: #000;
}

.related.white-bg .concerts {
  background: #FFFFFA;
}

.related.white-bg .avant-guardian .intro,
.related.white-bg .avant-guardian .intro a,
.related.white-bg .avant-guardian .big,
.related.white-bg .avant-guardian .big a,
.related.white-bg .avant-guardian .author,
.related.white-bg .concerts a .event-date,
.related.white-bg .concerts .event-date,
.related.white-bg h3,
.related.white-bg .big a, .related.white-bg h3 a,
.related.white-bg a .headerbands, .related.white-bg .headerbands,
.related.white-bg a .date, .related.white-bg .date,
.related.white-bg a .location, .related.white-bg .location {
  color: #000 !important;
}

.related.white-bg .square,
.related.white-bg .avant-guardian .big:before {
  background: #000;
}

.related .column:last-child:not(:first-child), .related .columns:last-child:not(:first-child) {
  float: left;
}

.related .related-content.closed {
  position: relative;
  overflow: hidden;
}

.related .related-content.closed::after {
  content: ' ';
  height: 250px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, transparent 100%);
}

.related.white-bg .related-content.closed::after {
  background: linear-gradient(to top, #fffffa 0%, rgba(255, 255, 255, 0) 100%);
}

.related .related-content-show-hand {
  text-align: center;
  margin-top: 0;
}

.related .show-hand {
  margin: 0 auto;
  content: '';
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  background-image: url(../images/icons/hand-white-90.svg);
  background-repeat: no-repeat;
  width: 40px;
  padding-top: 40px;
  background-size: 40px 40px;
  opacity: 0.5;
}

.related .show-hand:hover {
  opacity: 1;
}

.related.white-bg .show-hand {
  opacity: 0.3;
  background-image: url(../images/icons/hand-black-90.svg);
}

.related.white-bg .show-hand:hover {
  opacity: 1;
}

.related.white-bg {
  color: #000;
}

.concerts .related h5.ag,
.concerts .related .avant-guardian a {
  color: #FFFFFA;
}

.records .related-title:hover h2 {
  color: magenta !important;
}

.concerts .related-title:hover h2 {
  color: #00FFFF !important;
}

.avant-guardian .related-title:hover h2 {
  color: #470b47 !important;
}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"],
[type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"],
[type="time"], [type="url"], [type="color"], textarea {
  background: #000;
  padding: 5px;
  width: 80%;
  border: 3px solid #FFFFFA;
}

.iframe-container,
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
}

.iframe-container.sound,
.embed-container.sound {
  padding-bottom: 26.25%;
}

.embed-container iframe, .embed-container object, .embed-container embed,
.iframe-container iframe, .embed-container object, .iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}

.records .related .top-line {
  border-top: 1px solid magenta;
}

.avant-guardian .related .top-line {
  border-top: 1px solid #470b47;
}

/* =================================================
 _______  _______  _______  _______  _______ 
(  ____ )(  ___  )(  ____ \(  ____ \(  ____ | (    )|| (   ) || (    \/| (    \/| (    \/
| (____)|| (___) || |      | (__    | (_____ 
|  _____)|  ___  || | ____ |  __)   (_____  )
| (      | (   ) || | \_  )| (            ) |
| )      | )   ( || (___) || (____/\/\____) |
|/       |/     \|(_______)(_______/\_______)
                                                                                            
================================================= */
.homepage h1 {
  margin-top: 10px;
  font-size: 8vw;
  line-height: 8vw;
}

.homepage #homepage-content-slide {
  padding-top: 75px;
}

.homepage .image-hover {
  position: relative;
  cursor: pointer;
}

.homepage .image-hover.ag-image-hover {
  white-space: nowrap;
}

.homepage .image-hover-result {
  display: none;
  z-index: 1;
  position: absolute;
  width: 120%;
  top: -30px;
  left: 30px;
}

.homepage .image-hover:hover .image-hover-result {
  display: block;
}

.homepage a .top-text,
.homepage .top-text {
  color: #000;
  position: relative;
  z-index: 999;
}

.homepage .concerts-outer-block {
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px 12px 10px 12px;
}

.homepage .records {
  padding-top: 10px;
  padding-bottom: 0px;
}

.homepage .avant-guardian {
  padding-top: 10px;
  padding-bottom: 10px;
}

.homepage a {
  text-decoration: none;
  color: #000;
}

.homepage a span {
  text-decoration: underline;
}

.avant-guardian {
  /* square */
  /* detail */
  /* related specific for that ag page */
}

.avant-guardian .big, .avant-guardian .intro {
  position: relative;
  display: inline-block;
  padding-right: 4px;
  font-family: PDU, sans-serif;
  line-height: 26px;
}

.avant-guardian .big.big-as-block {
  line-height: 40px;
  top: 0px;
  width: 100%;
}

.avant-guardian .headerpic-block h2 {
  font-size: 52px;
}

.avant-guardian .intro {
  float: left;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 6px;
  width: 100%;
  color: #000;
}

.avant-guardian .square {
  content: "";
  position: absolute;
  top: 4px;
  right: 0px;
  right: -3px;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  background: #000;
}

.avant-guardian .author,
.avant-guardian .date {
  font-size: 12px;
  padding-left: 40px;
  line-height: 15px;
  text-transform: uppercase;
  clear: both;
  color: #000;
}

.avant-guardian .date a {
  color: #000;
}

.avant-guardian .by {
  text-transform: lowercase;
}

.avant-guardian .edition a,
.avant-guardian .author a {
  color: #000;
}

.avant-guardian .edition a:hover,
.avant-guardian .author a:hover {
  text-decoration: underline;
}

.avant-guardian .overview .author,
.avant-guardian .overview .date {
  padding-left: 40px;
}

.avant-guardian .ag-article,
.avant-guardian .ag-article p {
  font-family: Times;
  font-size: 21px;
  line-height: 26px;
}

.avant-guardian .ag-article a {
  color: #470b47;
  text-decoration: underline;
}

.avant-guardian .ag-article {
  margin-top: 50px;
}

.avant-guardian .ag-section {
  margin-top: 40px;
}

.avant-guardian .image-container {
  margin: 0 auto 40px auto;
  padding-right: 0;
}

.avant-guardian .no-focus .image-container {
  margin-bottom: 7px;
}

.avant-guardian .image-container.vertical-image {
  width: 70%;
}

.avant-guardian .image-caption {
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  text-align: center;
  margin-top: 3px;
  line-height: 15px;
}

.avant-guardian .big:before {
  content: "";
  position: relative;
  top: -16px;
  left: 0px;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  background: #000;
}

.avant-guardian .header-color-effect {
  position: relative;
  display: block;
}

.avant-guardian .header-color-effect .headerpic {
  display: block;
}

.avant-guardian a.hover-color-effect:hover {
  color: #470b47 !important;
}

.avant-guardian a.hover-color-effect:hover .headerpic-block h2 {
  color: #470b47 !important;
}

.avant-guardian a.hover-color-effect:hover .big, .avant-guardian a.hover-color-effect:hover .intro, .avant-guardian a.hover-color-effect:hover .author, .avant-guardian a.hover-color-effect:hover .author a, .avant-guardian a.hover-color-effect:hover .by, .avant-guardian a.hover-color-effect:hover .date, .avant-guardian a.hover-color-effect:hover .date a {
  color: #470b47 !important;
}

.avant-guardian a.hover-color-effect:hover .image-container {
  background: #470b47;
  position: relative;
}

.avant-guardian a.hover-color-effect:hover .image-container.de-neus-van-god,
.avant-guardian a.hover-color-effect:hover .image-container.artwork {
  background: #FFF;
}

.avant-guardian a.hover-color-effect:hover .image-container img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  display: block;
}

.avant-guardian a.hover-color-effect:hover .square, .avant-guardian a.hover-color-effect:hover .big:before {
  background: #470b47 !important;
}

.avant-guardian a.hover-color-effect:hover .headerpic {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  display: block;
}

.avant-guardian a.hover-color-effect:hover .headerpic h2 {
  color: #FFF !important;
}

.avant-guardian a.hover-color-effect:hover.header-color-effect {
  background: #470b47;
  width: 100%;
  display: block;
}

.avant-guardian .detail .header-intro {
  width: auto;
  margin: 20px auto;
  display: inline-block;
  float: none;
  position: relative;
  left: -20px;
}

.avant-guardian .detail .artwork-intro {
  margin-bottom: 0;
}

.avant-guardian .detail h1 {
  text-transform: uppercase;
  margin-top: 26.6666666667px;
  width: 100%;
  line-height: 80px;
}

.avant-guardian .detail h1::before {
  content: "";
  position: relative;
  top: -52px;
  left: 0px;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: #000;
}

.avant-guardian .detail .square {
  content: "";
  position: absolute;
  top: 32px;
  right: -40px;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: #000;
}

.avant-guardian .detail .image-container {
  clear: both;
  margin: 0 auto 20px auto;
}

.avant-guardian blockquote {
  padding-left: 40px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 21px;
  line-height: 26px;
  color: #000;
  font-style: italic;
  border: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.avant-guardian h5 {
  margin-top: 0;
  padding-left: 40px;
  text-transform: uppercase;
}

.avant-guardian .related .avant-guardian .intro a {
  color: #FFFFFA;
}

.avant-guardian .related .avant-guardian .author,
.avant-guardian .related .avant-guardian .date {
  padding-left: 26.6666666667px;
}

.concerts {
  background: #000;
  color: #FFFFFA;
  /* homepage */
  /* ---- detail ----- */
  /* ---- superfocus ----- */
  /* related specific for that concerts page----*/
}

.concerts h3 a, .concerts h3 {
  color: #FFFFFA;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 2px;
}

.concerts h2.no-margin-top, .concerts h2.no-margin-top a {
  margin-top: 0px;
}

.concerts a:hover h2 {
  color: #00FFFF;
}

.concerts a .headerbands,
.concerts .headerbands {
  font-family: PDU, sans-serif;
  font-size: 24px;
  line-height: 26px;
  color: #00FFFF;
}

.concerts .date-location {
  position: relative;
  margin-top: 6px;
}

.concerts .date-location::before {
  /* svg hand here */
  content: '';
  position: absolute;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  background-image: url(../images/icons/hand-cyan.svg);
  background-repeat: no-repeat;
  width: 30px;
  padding-top: 30px;
  background-size: 30px 30px;
  margin: 0;
  left: 0;
  top: -3px;
}

.concerts .no-focus .date-location {
  padding-left: 40px;
  padding-left: 37px;
}

.concerts a .event-date,
.concerts .event-date {
  font-family: PDU, sans-serif;
  font-size: 24px;
  line-height: 26px;
  color: #FFFFFA;
  text-transform: uppercase;
}

.concerts .tickets {
  margin: 10px 0;
  font-family: PDU, sans-serif;
  font-size: 24px;
  line-height: 26px;
}

.concerts .event-intro {
  margin: 30px 0;
  font-family: PDU, sans-serif;
  font-size: 24px;
  line-height: 26px;
}

.concerts .event-intro p {
  line-height: 26px;
}

.concerts .location {
  margin-top: 2px;
}

.concerts a .location,
.concerts .location {
  color: #FFFFFA;
  font-size: 17px;
  line-height: 20px;
}

.concerts a.hover-color-effect:hover {
  color: #00FFFF !important;
}

.concerts a.hover-color-effect:hover h1, .concerts a.hover-color-effect:hover h3, .concerts a.hover-color-effect:hover .text, .concerts a.hover-color-effect:hover .date-location, .concerts a.hover-color-effect:hover .event-date, .concerts a.hover-color-effect:hover .location {
  color: #00FFFF !important;
}

.concerts .band-listing h3 {
  margin-bottom: 20px;
  margin-bottom: 0;
  color: #00FFFF;
}

.concerts .line-up {
  margin-top: 50px;
  margin-bottom: 50px;
}

.concerts .line-up h4 {
  padding: 0;
  line-height: 26px;
}

.concerts .line-up .location {
  line-height: 25px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 24px;
  border-bottom: 1px solid #00FFFF;
}

.concerts .line-up .hour {
  width: 20%;
  min-width: 70px;
  font-feature-settings: "tnum";
  vertical-align: top;
}

.concerts .line-up .band {
  color: #00FFFF;
}

.concerts .line-up-tiny {
  font-size: 12px;
  line-height: 15px;
}

.concerts .band-info,
.concerts .band-info p {
  font-size: 17px;
  line-height: 20px;
}

.concerts .concerts-detail .image-container {
  margin-bottom: 20px;
}

.concerts .image-container.vertical-image {
  width: 70%;
}

.concerts blockquote {
  padding-left: 40px;
  padding-right: 40px;
  color: #FFFFFA;
  border: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 0;
  font-size: 17px;
  line-height: 20px;
}

.concerts .headerpic {
  clear: both;
  position: relative;
  min-height: 600px;
  margin-bottom: 30px;
  z-index: 100;
}

.concerts .headerpic .headerpic-image {
  /* -webkit-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);*/
  min-height: 600px;
  background: transparent center center no-repeat;
  background-size: cover;
}

.concerts .headerpic .headerpic-block {
  color: #000;
  top: 20px;
  left: 20px;
  margin: 0;
  color: #FFFFFA;
  font-size: 100px;
  line-height: 85px;
  text-transform: uppercase;
  font-family: PDU, sans-serif;
  cursor: pointer;
  text-align: left;
}

.concerts .headerpic .headerpic-block h1, .concerts .headerpic .headerpic-block .text {
  font-size: 100px;
  color: #000;
  line-height: 85px;
}

.concerts .related .avant-guardian .intro a {
  color: #FFFFFA;
}

.concerts .related .avant-guardian .author,
.concerts .related .avant-guardian .date {
  padding-left: 26.6666666667px;
}

.records {
  /* detail view -------- */
  /* tracklisting */
  /* spotify & bandcamp */
  /* related specific for that ag page */
}

.records .cover {
  margin-bottom: 10px;
}

.records h3 {
  padding: 0;
  display: inline-block;
  line-height: 32px;
  color: #000;
  margin-bottom: 0;
}

.records a h3 {
  color: #000;
}

.records .focus {
  margin-bottom: 20px;
}

.records a {
  color: magenta;
}

.records .format-listing {
  display: inline-block;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  vertical-align: top;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: -4px;
}

.records .image-container {
  position: relative;
  text-align: center;
}

.records .image-container:hover .status {
  display: block;
}

.records .status {
  display: none;
  position: absolute;
  color: magenta;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100px;
  text-align: center;
  padding: 3px 4px;
  border: 4px solid magenta;
  font-size: 32px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: PDU, sans-serif;
  cursor: pointer;
}

.records .teaser-text {
  clear: both;
  color: #000;
  margin-top: 0px;
  font-size: 24px;
  line-height: 26px;
  font-family: PDU, sans-serif;
}

.records .overview .teaser-text {
  margin-bottom: 20px;
}

.records .overview-many h3 {
  margin-bottom: 10px;
  color: #000;
}

.records a.hover-color-effect:hover {
  color: magenta !important;
}

.records a.hover-color-effect:hover .overview-many h3, .records a.hover-color-effect:hover h3, .records a.hover-color-effect:hover .teaser-text {
  color: magenta !important;
}

.records .detail .teaser-text {
  margin-top: 30px;
  margin-bottom: 30px;
}

.records .body-text {
  margin-top: 20px;
  font-size: 17px;
  line-height: 20px;
}

.records .body-text p {
  font-size: 17px;
  line-height: 20px;
}

.records .body-text h5 {
  color: #000;
}

.records .related-artist {
  margin: 40px 0;
  font-size: 17px;
  line-height: 20px;
}

.records .related-artist p {
  font-size: 17px;
  line-height: 20px;
}

.records .tracklisting {
  margin-top: 15px;
}

.records .small, .records .small p, .records .small ul, .records .small ol {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}

.records h5 {
  padding-left: 40px;
  color: magenta;
}

.records .record-cover-container {
  position: relative;
}

.records .record-cover-container .record-cover {
  position: relative;
}

.records .spotify-player {
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: 999;
}

.records .bandcamp-player {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 0;
  right: 0;
}

.records .record-cover.player:before {
  position: absolute;
  top: calc(50% - 40px);
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  width: 80px;
  height: 80px;
  border: 2px solid #FFFFFA;
  border-radius: 50%;
}

.records .record-cover.player::after {
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  right: 10px;
  margin: 0 auto;
  content: "";
  width: 24px;
  height: 24px;
  border-right: 2px solid #FFFFFA;
  border-top: 2px solid #FFFFFA;
  transform: rotate(45deg);
}

.records .record-cover.player:before {
  position: absolute;
  top: calc(50% - 40px);
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  width: 80px;
  height: 80px;
  border: 2px solid #FFFFFA;
  border-radius: 50%;
}

.records .record-cover.player.playing::before,
.records .record-cover.player.playing::after {
  display: none;
}

.records .related .avant-guardian .author,
.records .related .avant-guardian .date {
  padding-left: 26.6666666667px;
}

.records .concerts a .event-date, .records .concerts .event-date {
  color: #00FFFF;
}

/* in settings: $nav-font-size: 32px;
$nav-font-size-tablet: 28px;
$nav-font-size-wide: 38px;

$nav-medium-line-height: 36px;
$nav-line-height-size-tablet: 36px;
$nav-line-height-size-wide: 36px; */
/* medium view */
@media only screen and (max-width: 1023px) {
  .mobile-clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

/* mobile and ipad view */
@media only screen and (max-width: 860px) {
  .homepage h1 {
    font-size: 11vw;
    line-height: 11vw;
  }
  .content {
    padding-top: 100px;
  }
  .column, .columns {
    padding-bottom: 20px;
  }
  .brand-logo-svg {
    margin-top: 10px;
  }
  .concerts .headerpic {
    min-height: 400px;
  }
  .concerts .headerpic .headerpic-block h1, .concerts .headerpic .headerpic-block .text {
    font-size: 50px;
    line-height: 50px;
  }
  /* indent is not/half needed */
  .padding-indent-half {
    padding-left: 0px;
    padding-right: 0px;
  }
  .padding-indent {
    padding-left: 40px;
    padding-right: 40px;
  }
  /* masonry */
  .masonry-sizer,
  .masonry-item {
    width: 50% !important;
    padding-right: 20px;
    padding-left: 0px;
  }
  /* 4 columns */
  .masonry-sizer,
  .masonry-item {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .masonry-item-double {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .avant-guardian .masonry-sizer,
  .avant-guardian .masonry-item {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .band-listing .masonry-sizer,
  .band-listing .masonry-item {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .masonry-one-col-mobile .masonry-sizer,
  .masonry-one-col-mobile .masonry-item {
    width: 50%;
    padding-right: 20px;
    padding-left: 10px;
  }
  /* headerpic */
  .headerpic {
    min-height: 400px;
  }
  .records .overview .teaser-text {
    display: none;
  }
  .records .detail .teaser-text {
    margin-top: 0;
  }
  .mobile-no-split {
    width: 100%;
    padding-right: 0;
  }
  .related-content .related .column, .related .columns {
    padding-right: 10px;
  }
  .concerts .headerpic .headerpic-image {
    min-height: 400px;
  }
  .concerts .headerpic .headerpic-block h1, .concerts .headerpic .headerpic-block .text {
    font-size: 40px;
    line-height: 38px;
  }
  .avant-guardian .big {
    line-height: 45px;
    width: 100%;
  }
  .avant-guardian .detail .header-intro {
    margin: 0 auto;
    left: 0;
  }
  .avant-guardian .detail .square {
    display: none;
  }
  .avant-guardian .detail h1::before {
    display: none;
  }
  .avant-guardian .detail h1 {
    font-size: 65px;
    line-height: 60px;
  }
  .avant-guardian .detail blockquote {
    padding-right: 0;
  }
  h1 {
    font-size: 65px;
    line-height: 70px;
  }
  .move-to-left-on-mobile {
    float: left !important;
  }
}

/* iphone 5 only */
@media handheld, only screen and (max-width: 640px) {
  /* masonry */
  .masonry-sizer,
  .masonry-item {
    width: 100% !important;
    padding-right: 20px;
    padding-left: 0px;
  }
  /* 4 columns */
  .masonry-sizer,
  .masonry-item {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .masonry-item-double {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .avant-guardian .masonry-sizer,
  .avant-guardian .masonry-item {
    width: 100%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .band-listing .masonry-sizer,
  .band-listing .masonry-item {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .masonry-one-col-mobile .masonry-sizer,
  .masonry-one-col-mobile .masonry-item {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* tablet view (orange) */
@media handheld, only screen and (min-width: 860px) and (max-width: 1080px) {
  .content {
    padding-top: 90px;
  }
  .brand-logo-svg {
    width: 60px;
    padding-top: 60px;
    background-size: 60px 60px;
    margin: -10px 0 0 0;
  }
}

@media handheld, only screen and (min-width: 1081px) and (max-width: 1180px) {
  .content {
    padding-top: 100px;
  }
}

/* ultru wide view */
@media handheld, only screen and (min-width: 1350px) {
  .content {
    padding-top: 115px;
  }
}

/* ultru ultra wide view */
@media handheld, only screen and (min-width: 1650px) {
  .content {
    padding-top: 150px;
  }
}
