@charset 'utf-8';
@import 'settings';
@import 'navigation-bar/navigation';

@import url('https://fonts.googleapis.com/css?family=Rubik');

// KRAAK MAIN CSS FILE :)
// 
// 1 GLOBAL : headers, links
// ---------

html { 
  height:100%;
}
body {
  width:100%;
  height:100%;
  min-height: 100%;
  font-size: $text-font-size;
  line-height: $text-font-size+5px;
  background: $white;
  font-family: $body-font-family;  
  padding-left: 0px;
  padding-right: 0px;
}
body.overlay-active {
  overflow:hidden;
}
/* sticky footer */
.wrapper {  min-height: 100%; margin: 0 auto -229-20px; }
footer, .push { clear:both; min-height: 229+20px;}
.push { content: ""; display: block; clear: both;}

h1 {
  font-size: $extra-large-font-size;
  font-family: $special-font-family;
  line-height: $extra-large-font-size-line-height-normal;
}
.big,
h2 {
  font-size: $large-font-size;
  font-family: $special-font-family;
  line-height: $large-font-size-line-height-normal;
  text-transform: uppercase;
  color: $black;
}

h3 {
  font-size: $medium-font-size;
  line-height: $medium-font-size-line-height-normal;
}
h4 {
  font-size: $intro-font-size;
  line-height: $intro-font-size-line-height-narrow;
}
.big a, h2 a {  color: $black; }
.big a:hover, h2 a:hover {  text-decoration: underline; }

/* eg subtitles avant guardian or schedule concerts*/
h4 {
  padding-left: $left-space/2;
}
/* eg avant guardian names interviewer */
h5 {
  font-size: $small-font-size;
  font-family: $body-font-family;  
  font-weight: 400;
  margin: 30px 0 0 0;
  line-height: $small-font-size-line-height-close;
}
.small {
  font-size: $small-font-size;
  line-height: $small-font-size-line-height-close;  
}
.euro-sign {
  font-family: 'PDU-euro';
}

.button {
  border:3px solid $cyan;
  padding: 5px 10px;
  background: transparent;
  color: $cyan;
  font-family: $special-font-family;
  font-size: $medium-font-size;
  transition-duration:0;
  &:hover {
    border-color: $white;
    color: $white;
    background: transparent;
  }
}
.records .button{
  border-color: $magenta;
  &:hover, &:focus {
    border-color: $magenta;
    color: $white;
    background: magenta;
  }
}

table {
  background: transparent;
  border:0;
  tbody,
  tr,
  tr:nth-child(even) {
    background: transparent;
    border:0;
  }
  td {
    padding: 0 2px;
  }
}

.split {
  width:50%; 
  float: left;
  padding-right: 15px;
}

.padding-indent {
  padding-left: $left-space;
  padding-right: $left-space;
}
.padding-indent-half {
  padding-left: $left-space/2;
  padding-right: $left-space/2;
}
.padding-indent-half.padding-indent-half-keep-on-mobile {
  padding-left: $left-space/2;
  padding-right: $left-space/2;
}
.image-container img {
  width:100%;
}

.align-right { text-align: right; }
.align-center { text-align: center; }

/* grid light up
.row {
  border:1px solid yellow;
}
.content {
  border:1px solid blue;
}
*/
// 2 GLOBAL CONTENT, BLOCKS
// Block are used when you want eg a full width background color with inside a row and columns n
// -----------------------------------------
.content {
  padding: 105px 10px $content-default-padding-top-bottom 10px;
}

.homepage .content{
  padding: 0 10px $content-default-padding-top-bottom  10px;
}

.block {
   padding: $block-default-padding;
}
.block.inside-page {
  padding: 25px 10px $content-default-padding-top-bottom 10px;  
}
/* no padding */
.no-padding {
  padding: 0;
}
/* basic padding */
.basic-padding-top {
  padding-top: $block-basic-padding;
}
.basic-padding-bottom {
  padding-bottom: $block-basic-padding;
}
/*  large */
.extra-padding-top {
  padding-top: $block-extra-padding;
}
.extra-padding-bottom {
  padding-bottom: $block-extra-padding;
}
.band-info ul li,
.ag-article ul li, 
.body-text ul li {
  list-style-type: none;
}
.band-info ul li:before ,
.ag-article ul li:before , 
.body-text ul li:before  {
  content: "- ";
}

// 3 ICONS
// Block are used when you want eg a full width background color with inside a row and columns n
// -----------------------------------------
.icon {
    height: 0;
    content: ' ';
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    font-size: 0;
    width: 60px;
    padding-top: 60px;
    background-size: 60px 60px;
    background-image: url(../images/icons/logo-color-transparent.svg);    
    margin: 0;
}


// 4 HEADER
// -----------------------------------------

// FULL BLOCK PICTURE
.headerpic {
  clear: both;
  background: transparent center center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  min-height: $header-picture-height;
  margin-bottom: 10px;
  z-index: 100;
}

.headerpic .headerpic-block,
.headerpic-special-block {
  position: absolute;
  text-align: center;
  font-size: 25px;
  height: 100px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: bottom;
}
.headerpic-special-block {
  z-index:919;
  h2 {
    color: #FFF;
  }
}

.headerpic .headerpic-block h2 {
  font-family: $special-font-family ;
  font-size: $large-font-size;
  text-align: center;
  text-transform: uppercase;
  color: $white;
}


// 5. NAVIGATION
// -----------------------------------------

.brand-logo-svg {
    height: 0;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    font-size: 0;
    background-image: url(../images/common/logo-color-transparent.svg);
    width: 70px;
    padding-top: 70px;
    background-size: 70px 70px;
    margin: -2px 0 0 0;
    float: right;
}
.homepage .brand-logo-svg,
.concerts .brand-logo-svg{
  background-image: url(../images/common/logo-color-black-bg.svg);
}

// 6. FOOTER
// -----------------------------------------

.fixednews {
  border-bottom:1px solid rgba(255, 255, 250, 0.3); 
  padding: 15px 0 30px 0; 
  margin-bottom:25px; 
  text-align:center;
  a {
    text-decoration: underline;
  }
}
footer {
  padding: $content-default-padding-top-bottom 0 $content-default-padding-top-bottom 0;
  background-color: $footer-background-color;
  color: $footer-font-color;
  font-size:$footer-font-size;
  line-height: $footer-font-line-height;
  border-top:1px solid rgba($white, 0.3); 
  clear: both;
  margin-top: 20px;
  min-height:229px;
  h4 {
    padding-left:0;
  }
  a {
    color: $footer-font-color!important;
  }
  input,
  .button {
    border-color: $white;
    color: $white;
    margin-bottom: 0;
  }
  input[type="text"], [type="text"]:focus { 
    width: 100%;
    position: relative;
    top: 4px;
    background-color: $black;
  }
  .button, .button:hover, .button:focus {
    position: relative;
    top: -3px;
    width: auto;
    border-color: $white!important;
  }
  img.partner {
    width:35%;
    margin-right:2%;
    display:inline-block;
    margin-top:5px;
  }

}
footer.home {
  background:$white;
  color: $black;
  min-height: 100px;
  text-align: left;
  margin-top: 50px;
  a {
    color: $black;
    text-decoration: underline;
  }
  h4,
  h4 a {
    color: $black !important;;
  }
}
// 7. MASONRY
// http://w3bits.com/css-masonry/
// -----------------------------------------

.masonry-sizer,
.masonry-item { width: 33%; padding-right: 10px; padding-left: 10px; padding-bottom: 25px;}
/* 4 columns */
.masonry-four-col {
  .masonry-sizer,
  .masonry-item { width: 25%; padding-right: 10px; padding-left: 10px; padding-bottom: 50px; }
  .masonry-item-double { width: 50%; padding-right: 10px; padding-left: 10px; }
}
// av grid has bigger spacing between the columns
.avant-guardian .masonry { padding-top: 25px; }
.avant-guardian .masonry-sizer,
.avant-guardian .masonry-item { width: 33%; padding-right: 35px; padding-left: 35px; padding-bottom: 50px;}

// av grid has bigger spacing between the columns
.band-listing  .masonry {  }
.band-listing  .masonry-sizer,
.band-listing  .masonry-item { width: 50%; padding-right: 50px; padding-left: 50px; padding-bottom: 40px; }



// 8. OVERLAY
// -----------------------------------------

/* The Overlay (background) */

.overlay {
  height: 0%; /* put on 0% to slide it open */
  width: 100%;
  position: fixed; 
  z-index: 991;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0, 0.8); 
  overflow-x: hidden; 
 // transition: 0.5s; 

  /* special colors overlay */
  &.concerts {
    background-color: rgba($cyan, 0.9); 
    a {
      color: $black;
    }
  }
  &.records {
    background-color: rgba($magenta, 0.9); 
    a {
      color: $white;
    }
    .title {
      color: $black;
    }
  }
  &.avant-guardian {
    background-color: rgba($purple, 0.9);
    a {
      color: $white;
    } 
    .title {
      color: $medium-gray;
    }
  }
  .count {
    display: inline-block;
    padding:0;
    font-size: $small-font-size;
    font-weight: 400;      
    vertical-align: top; 
    font-family: $body-font-family;
    position:relative;
    top:-4px;
  }
  .content {
    font-family: $special-font-family;
    font-size: $medium-font-size;
    line-height: $medium-font-size;
    color: $white;
  }
  .title {
    font-family: $special-font-family;
    font-size: $medium-font-size;
    line-height: $medium-font-size;
    color: $white;
    text-decoration: underline;
    margin-bottom:5px;
  }
  /* Position the close button (top right corner) */
  .closebtn {
    position: absolute;
    top: 40px;
    right: 55px;
    font-size: 60px;
    color: $white; 
    &:hover {
      text-decoration: none;
    }
}
  a {
   
  }
  a:hover {
    text-decoration: underline;
  }
}



// 9. RELATED
// -----------------------------------------

.related {
  position: relative;
  background: $black;
  color: $white;
  margin: 0;
  padding: 0!important;
  // opening titles
  .related-title {
    cursor:pointer;
  }
  .related-title h2 {
    font-size: $large-font-size;
    line-height: $large-font-size-line-height-narrow;  
    color: $white;
    text-align:center;
    margin:0 auto;
    position: relative;
    top:-15px;
    margin-left: 17px;
    padding-bottom: 0;
    margin-bottom:0;
  }
  


  &.white-bg .related-title h2 {
    color: $black;
  }
  &.white-bg .related-title.js-close-content h2,
  &.white-bg .related-title:hover h2 {
    color: $black;
  }

  .row {
    padding: 25px 0;
    margin-top: 50px;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .row.related-content {
    margin-top: 0px;
    padding-top: 0px;
  }
  /* hack for the border line equal width */
  .column, .columns {
    padding-left: 0;
    //margin-right: 0.625rem;
    padding-right: 35px;
   }
   .column.last, .columns.last {
    padding-left: 0;
    //margin-right: 0.625rem;
    padding-right: 0;
   }

  .top-line {
    border-top: 1px solid $cyan;
  }
  
  .big a, h3 a { 
    color: $white;
  }
  .concerts h3 {
    margin-top:0;
  }
  h5 {
    padding-left: $left-space/2 !important;
    margin: 0 10px 15px 0;    
  }
  .records h3 {
    margin-top: 6px;
    margin-bottom:10px;
  }
  .records h5 {
    color: $magenta;
  }
  .concerts h5 {
    color: $cyan;
  }
  h5.ag {
    margin-bottom: 17px; /* not clean */
    color: $purple;
  }
  
   
  .avant-guardian .square,
  .avant-guardian .big:before {
    background: $white;
  }
  .avant-guardian .intro,
  .avant-guardian .big,
  .avant-guardian .date,
  .avant-guardian .author {
    color: $white;
  }
  .item {
    margin-bottom: 25px;
  }

  /* avant-guardian */
  .avant-guardian .intro {
    margin-bottom:0;
    padding-bottom:7px;
    width:100%;
  }
  .avant-guardian .item {
    position: relative;
    top:-5px;
   }
  .avant-guardian .square {
    top: 5px;
  }
 
  /* concerts */
  .concerts {
    background: $black;    
  }
  .concerts .image-container {
    margin-bottom: 7px;
  }
  .concerts a .date, .concerts .date {
    padding-left: 0;
  }
  .concerts .date-location {
    padding-left: $left-space/2;
  }
  .concerts .item {
   position: relative;
   top:-2px;
   margin-bottom: 30px;
  }

  /* ---- white version ------ */
  &.white-bg {
    background: $white;
    color: $black;

    .concerts {
      background: $white;
    }

    .avant-guardian .intro,
    .avant-guardian .intro a,
    .avant-guardian .big,
    .avant-guardian .big a,
    .avant-guardian .author,
    .concerts a .event-date, 
    .concerts .event-date,
    h3, 
    .big a, h3 a,
    a .headerbands, .headerbands,
    a .date, .date,
    a .location, .location {
      color: $black!important;
    }
   
  
    .square,
    .avant-guardian .big:before {
      background: $black;
    }
  }

  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float:left;
  }
  /* degradee effect */
  .related-content.closed {
    position: relative;
    overflow: hidden;
  }
  .related-content.closed::after {
    content: ' ';
    height: 250px;
    position: absolute;
    width: 100%;
    top:0;
    left: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%); 
  }
  &.white-bg .related-content.closed::after {
    background: linear-gradient(to top, rgba($white,1) 0%,rgba(255,255,255,0) 100%); 
  }
  .related-content-show-hand {
    text-align: center;
    margin-top: 0;
  }
  .show-hand {
    margin:0 auto;
    content: '';
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    font-size: 0;
    background-image: url(../images/icons/hand-white-90.svg);
    background-repeat: no-repeat;
    width: 40px;
    padding-top: 40px;
    background-size: 40px 40px;
    opacity: 0.5;
  }
  .show-hand:hover {
    opacity: 1;
  }
  &.white-bg .show-hand {
    opacity: 0.3;
    background-image: url(../images/icons/hand-black-90.svg);    
  }
  &.white-bg .show-hand:hover {
    opacity: 1;
  }
  &.white-bg  {
    color: $black;
  }
  
}
.concerts .related h5.ag,
.concerts .related .avant-guardian a {
  color: $white;
}
.records .related-title:hover h2 { color: $magenta!important;}
.concerts .related-title:hover h2 { color: $cyan!important; }
.avant-guardian .related-title:hover h2 { color: $purple!important; }


// FORM 
// -----------------------------------------
[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], 
[type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], 
[type="time"], [type="url"], [type="color"], textarea {
  background: $black;
  padding: 5px;
  width: 80%;
  border: 3px solid $white;
}


// EMBEDS VIDEO ... 
// -----------------------------------------
.iframe-container,
.embed-container { 
  position: relative; padding-bottom: 56.25%; 
  height: 0; overflow: hidden; max-width: 100%; 
  text-align:center;  
} 
.iframe-container.sound,
.embed-container.sound{ 
  padding-bottom: 26.25%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed,
.iframe-container iframe, .embed-container object, .iframe-container embed { 
  position: absolute; top: 0; left: 0; width: 100%!important; height: 100%; 
}
.records  .related .top-line{
  border-top: 1px solid $magenta;
}
.avant-guardian .related  .top-line{
  border-top: 1px solid $purple;
}

/* =================================================
 _______  _______  _______  _______  _______ 
(  ____ )(  ___  )(  ____ \(  ____ \(  ____ \
| (    )|| (   ) || (    \/| (    \/| (    \/
| (____)|| (___) || |      | (__    | (_____ 
|  _____)|  ___  || | ____ |  __)   (_____  )
| (      | (   ) || | \_  )| (            ) |
| )      | )   ( || (___) || (____/\/\____) |
|/       |/     \|(_______)(_______/\_______)
                                                                                            
================================================= */

// -----------------------------------------
// --------- HOMEPAGE 
.homepage {
  h1 {
    margin-top:10px;
    font-size: 8vw;
    line-height: 8vw;
  }
  #homepage-content-slide {
    padding-top: 75px;
  }
  .image-hover {
    position: relative;
    cursor: pointer;
   }
  .image-hover.ag-image-hover {
    white-space: nowrap; 
  }
  .image-hover-result {
    display: none;
    z-index:1;
    position: absolute;
    width: 120%;
    top: -30px;
    left: 30px;
  }
  .image-hover:hover .image-hover-result  {
    display: block;
  } 
  a .top-text,
  .top-text {
    color: $black;
    position: relative;
    z-index: 999;
  }
  // exceptions
  .concerts-outer-block {
    margin-left:-10px;
    margin-right:-10px;
    padding: 20px 12px 10px 12px;
  }
  .records {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .avant-guardian {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  a {
    text-decoration: none;
    color: $black;
    span {
      text-decoration: underline;
    }
  }

}






// -----------------------------------------
// ---------  AVANT GUARDIAN

.avant-guardian {
  .big, .intro {
    position:relative;
    display: inline-block;
    padding-right:4px;
    font-family: $special-font-family;
    line-height: $intro-font-size-line-height-narrow;
  }
  .big.big-as-block {
    line-height:$large-font-size-line-height-narrow;
    top:0px;
    width:100%;
  }
  .headerpic-block h2 {
    font-size: $large-font-size+10px;
  }
  .intro {
    float: left;
    font-size: $intro-font-size;
    line-height: $intro-font-size-line-height-narrow;
    margin-bottom: 6px;
    width:100%;
    color: $black;
  }
 
  /* square */
  .square {
    content: "";
    position:absolute;
    top: 4px;
    right: 0px;
    right:-3px;
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background: $black;
  }
  .author,
  .date {
    font-size: $small-font-size;
    padding-left: $left-space/2;
    line-height: $small-font-size-line-height-close;
    text-transform: uppercase;
    clear: both;
    color: $black;
  }
  .date a {
    color: $black;
  }
  .by {
    text-transform: lowercase;
  }
  .edition a,
  .author a {
    color: $black;
  }
  .edition a:hover,
  .author a:hover {
    text-decoration: underline;
  }
  .overview .author,
  .overview .date{
    padding-left: $left-space/2;
  }
  .ag-article,
  .ag-article p {
    font-family: $serif-font-family;
    font-size: $article-font-size;
    line-height: $article-font-size-line-height-normal;
  }
  .ag-article a {
    color: $purple;
    text-decoration: underline;
  }
  .ag-article {
    margin-top: 50px;
  }
  .ag-section {
    margin-top:40px;
  }
  .image-container {
    margin: 0 auto $left-space/2 auto;
    padding-right:0;
  }
  .no-focus .image-container  {
    margin-bottom: 7px;
  }
  .image-container.vertical-image {
    width:70%;
  }
  .image-caption {
    font-size: $small-font-size;
    font-family: $body-font-family;
    text-align: center;
   // text-transform: uppercase;
    margin-top: 3px;
    line-height: $small-font-size-line-height-close;
  }
  .big:before {
      content: "";
      position:relative;
      top: -16px;
      left: 0px;
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 5px;
      background: $black;
  }
  .header-color-effect {
    position: relative;
    display: block;
    .headerpic {
      display: block;
    }
  }
  a.hover-color-effect:hover {
    color: $purple!important;
    .headerpic-block h2 {
      color: $purple!important;
    }
    .big, .intro, .author, .author a, .by, .date, .date a {
      color: $purple!important;
    }
    .image-container {
      background: $purple;
      position: relative;
    }
    .image-container.de-neus-van-god,
    .image-container.artwork {
      background: #FFF;
    }
    .image-container img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
      display: block;
    }
    .square, .big:before {
      background:$purple !important;
    }
    .headerpic {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
      display: block;
      h2 {
        color: #FFF !important;
      }
    }
    &.header-color-effect {
      background: $purple;
      width:100%;
      display: block;
    }
   
  }

  /* detail */
  .detail  .header-intro {
    width:auto;
    margin: 20px auto;
    display: inline-block;
    float: none;
    position: relative;
    left: -20px;
  }
  .detail .artwork-intro {
    margin-bottom:0;
  }
  .detail h1 {
    text-transform: uppercase;
    margin-top: $left-space/3;
    width:100%;
    line-height: $extra-large-font-size-line-height-extreme-narrow;
  }
  .detail h1::before {
      content: "";
      position:relative;
      top: -52px;
      left: 0px;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background: $black;
  }
  .detail .square {
    content: "";
    position:absolute;
    top: 32px;
    right: -40px;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    background: $black;
  }
  .detail .image-container {
    clear:both;
    margin: 0 auto 20px auto;
  }
  
  blockquote {
    padding-left: $left-space/2;
    padding-right: $left-space;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: $article-font-size;
    line-height: $article-font-size-line-height-normal;
    color: $black;
    font-style: italic;
    border:0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
    
  h5 {
    margin-top:0;
    padding-left: $left-space/2;
    text-transform: uppercase;
  }
  /* related specific for that ag page */
  .related .avant-guardian .intro a {
    color: $white;
  }
  .related .avant-guardian .author, 
  .related .avant-guardian .date {
    padding-left: $left-space/3;
  }
 
}
// -----------------------------------------
// --------- CONCERTS 
.concerts {
  background: $black;
  color: $white;
  h3 a, h3 {
    color: $white;
    text-transform: none;
    margin-top:10px;
    margin-bottom:2px;
  }
  /* homepage */
  h2.no-margin-top, h2.no-margin-top a {
    margin-top:0px;
  }
  a:hover h2 {
    color: $cyan;
  }
  a .headerbands,
  .headerbands {
    font-family: $special-font-family;
    font-size: $intro-font-size;
    line-height: $intro-font-size-line-height-narrow;
    color: $cyan;
  }
  .date-location {
    position: relative;
    margin-top:6px;
  }
  .date-location::before {
     /* svg hand here */
     content: '';
     position: absolute;
     display: inline-block;
     overflow: hidden;
     cursor: pointer;
     font-size: 0;
     background-image: url(../images/icons/hand-cyan.svg);
     background-repeat: no-repeat;
     width: 30px;
     padding-top: 30px;
     background-size: 30px 30px;
     margin: 0;
     left: 0;
     top:-3px;
  }
  .no-focus  .date-location {
    padding-left:$left-space/2;
    padding-left:37px;
  }
  a .event-date,
  .event-date {
    font-family: $special-font-family;
    font-size: $intro-font-size;    
    line-height: $intro-font-size-line-height-narrow;
    color: $white;    
    text-transform: uppercase;
  }
  .tickets {
    margin: 10px 0;
    font-family: $special-font-family;
    font-size: $intro-font-size;    
    line-height: $intro-font-size-line-height-narrow;
  }
  .event-intro {
    margin: 30px 0;
    font-family: $special-font-family;
    font-size: $intro-font-size;    
    line-height: $intro-font-size-line-height-narrow;
  }
  .event-intro p {
    line-height: $intro-font-size-line-height-narrow;
  }
  .location {
    margin-top: 2px;
  }
  a .location,
  .location {
    color: $white;   
    font-size: $text-font-size; 
    line-height: $text-font-size-line-height-close;
  }
  a.hover-color-effect:hover {
    color: $cyan !important;
    h1, h3, .text, .date-location, .event-date, .location {
      color: $cyan !important;
    }
  }
  /* ---- detail ----- */

  .band-listing h3 {
    margin-bottom: 20px;    
    margin-bottom: 0;
    color: $cyan;
  }
  .line-up {
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      padding:0;
      line-height: $intro-font-size-line-height-narrow;
    }
    .location {
      line-height: 25px;
      padding-bottom: 5px;
      margin-bottom:5px;
      font-size: $intro-font-size;          
      border-bottom:1px solid $cyan;
    }
    .hour {
      width: 20%;
      min-width: 70px;
      font-feature-settings: "tnum";
      vertical-align: top;
    }
    .band {
      color: $cyan;
    }
  }
  .line-up-tiny {
    font-size: $small-font-size;
    line-height: $small-font-size-line-height-close;
  }

  .band-info,
  .band-info p {
    font-size: $text-font-size;
    line-height: $text-font-size-line-height-close;
  } 

  .concerts-detail .image-container {
    margin-bottom: 20px;
  }
  .image-container.vertical-image {
    width:70%;
  }
  blockquote {
    padding-left: $left-space/2;
    padding-right: $left-space/2;
    color: $white;
    border:0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 0;
    font-size: $text-font-size;
    line-height: $text-font-size-line-height-close;
  }
  /* ---- superfocus ----- */
  .headerpic {
    clear: both;
    position: relative;
    min-height: $header-picture-height;
    margin-bottom: 30px;
    z-index: 100;    
    .headerpic-image {
      /* -webkit-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);*/
      min-height: $header-picture-height;
      background: transparent center center no-repeat;
      background-size: cover;
    }
    &:hover  .headerpic-image {
      
    }
    .headerpic-block {
      color: $black;
      top:20px;
      left:20px;
      margin: 0;
      color: $white;
      font-size: $extra-large-font-size;
      line-height: $extra-large-font-size-line-height-narrow;
      text-transform: uppercase;
      font-family: $special-font-family;
      cursor:pointer;
      text-align: left;
      h1, .text {
        font-size: $extra-large-font-size;
        color: $black;
        line-height: $extra-large-font-size-line-height-narrow;
      }
    }
    
  }
  
  /* related specific for that concerts page----*/ 
  .related .avant-guardian .intro a {
    color: $white;
  }
  .related .avant-guardian .author, 
  .related .avant-guardian .date {
    padding-left: $left-space/3;
  }
  
  
}
// -----------------------------------------
// --------- RECORDS 

.records {
    .cover {
      margin-bottom: 10px;
    }
    
    h3 {
      padding:0;
      display: inline-block;
      line-height: $medium-font-size-line-height-narrow+2;
      color: #000;
      margin-bottom:0;
    }
    a h3 {
      color: #000;
    }
    .focus {
      margin-bottom: 20px;
    }
    a {
      color: $magenta;
    }
    .format-listing {
      display: inline-block;
      padding:0;
      font-size: $small-font-size;
      font-weight: 400;      
      vertical-align: top; 
      font-family: $body-font-family;
      position:relative;
      top:-4px;
    }
    .image-container {
      position: relative;
      text-align: center;
    }
    .image-container:hover .status {
      display: block;
    }
    .status {
      display: none;
      position: absolute;
      color: $magenta;
      top: 50%; left: 50%;
      transform: translate(-50%,-50%);
      min-width: 100px;
      text-align: center;
      padding: 3px 4px;
      border:4px solid $magenta; 
      font-size: $medium-font-size;
      line-height: $medium-font-size-line-height-narrow;
      text-transform: uppercase;
      font-family: $special-font-family;
      cursor:pointer;
    }
    .teaser-text {
      clear:both;
      color: $black;
      margin-top: 0px;
      font-size: $intro-font-size;
      line-height: $intro-font-size-line-height-narrow;
      font-family: $special-font-family
    }
    .overview .teaser-text {
      margin-bottom: 20px;
    }
    .overview-many h3 {
      margin-bottom: 10px;
      color: $black;
    }
    a.hover-color-effect:hover {
      color: $magenta!important;
     .overview-many h3, h3, .teaser-text{
        color: $magenta!important;
      }
    }
    /* detail view -------- */
    .detail .teaser-text {
      margin-top: 30px;
      margin-bottom: 30px;
    }  
    .body-text {
      margin-top: 20px;
      font-size: $text-font-size;
      line-height: $text-font-size-line-height-close;
      p  {
        font-size: $text-font-size;
        line-height: $text-font-size-line-height-close;
      }
      h5 {
        color: $black;
      }
    }
    .related-artist {
      margin: 40px 0;
      font-size: $text-font-size;
      line-height: $text-font-size-line-height-close;
      p  {
        font-size: $text-font-size;
        line-height: $text-font-size-line-height-close;
      }
    }

    /* tracklisting */
    .tracklisting {
      margin-top: 15px;
    }
    .small, .small p, .small ul, .small ol {
      font-size: $small-font-size;
      line-height: $small-font-size-line-height-close;  
      margin-bottom: 0;
    }

    h5 {
      padding-left: $left-space/2;
      color: $magenta;
    }
    .record-cover-container {
      position: relative;
      .record-cover {
        position: relative;
      }
    }
    /* spotify & bandcamp */
    .spotify-player {
      position: absolute;
      top:0;
      cursor: pointer;      
      left:0;    z-index: 999;
     }
    .bandcamp-player {
      position: absolute;
      bottom:0;
      cursor: pointer;      
      left:0;
      right:0;
    }
    .record-cover.player:before {
      position: absolute;
      top: calc(50% - 40px);
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      width: 80px;
      height: 80px;
      border: 2px solid $white;
      border-radius: 50%;
    }
    .record-cover.player::after {
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      right: 10px;
      margin: 0 auto;
      content: "";
      width: 24px;
      height: 24px;
      border-right: 2px solid $white;
      border-top: 2px solid $white;
      transform: rotate(45deg);
    }
    .record-cover.player:before {
      position: absolute;
      top: calc(50% - 40px);
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      width: 80px;
      height: 80px;
      border: 2px solid $white;
      border-radius: 50%;
    }
    .record-cover.player.playing::before,
    .record-cover.player.playing::after {
      display: none;
    }

    /* related specific for that ag page */
    .related .avant-guardian .author, 
    .related .avant-guardian .date {
      padding-left: $left-space/3;
    }
    .concerts a .event-date, .concerts .event-date {
      color: $cyan;
    }
  
  
  
}

// ==================================================================================
// ==================================================================================
// ==================================================================================
// ==================================================================================
// ==================================================================================
// ==================================================================================
// 11. RESPONSIVE
// -----------------------------------------

 /* in settings: $nav-font-size: 32px;
$nav-font-size-tablet: 28px;
$nav-font-size-wide: 38px;

$nav-medium-line-height: 36px;
$nav-line-height-size-tablet: 36px;
$nav-line-height-size-wide: 36px; */  
   

/* medium view */
@media only screen and (max-width:1023px) {
  .mobile-clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
/* mobile and ipad view */

@media only screen and (max-width: $nav-breakpoint) {

  .homepage h1 {
    font-size: 11vw;
    line-height: 11vw;
  }
  .content {
    padding-top: 100px;
  }
  .column, .columns {
    padding-bottom: 20px;
  }
  .brand-logo-svg {
    margin-top: 10px;
  }
  .concerts .headerpic {
    min-height: 400px;
  }
  .concerts .headerpic .headerpic-block h1, .concerts .headerpic .headerpic-block .text {
    font-size: 50px;
    line-height: 50px;
  }


  /* indent is not/half needed */
  .padding-indent-half {
    padding-left: 0px;
    padding-right: 0px;
  }
  .padding-indent {
    padding-left: 40px;
    padding-right: 40px;
  }
 

  /* masonry */
  .masonry-sizer,
  .masonry-item { width: 50% !important; padding-right: 20px; padding-left: 0px; }
  /* 4 columns */
  .masonry-sizer,
  .masonry-item { width: 50%; padding-right: 20px; padding-left: 0px;  }
  .masonry-item-double { width: 50%; padding-right: 20px; padding-left: 0px; }
  // av grid has bigger spacing between the columns
  .avant-guardian .masonry-sizer,
  .avant-guardian .masonry-item { width: 50%; padding-right: 20px; padding-left: 0px; }
  
  // av grid has bigger spacing between the columns
  .band-listing  .masonry-sizer,
  .band-listing  .masonry-item { width:50%; padding-right: 20px; padding-left: 0px; }
  
  .masonry-one-col-mobile .masonry-sizer,
  .masonry-one-col-mobile .masonry-item { width:50%; padding-right: 20px; padding-left: 10px; }


  /* headerpic */
  .headerpic {
    min-height: 400px;
  }

   // records
   .records .overview .teaser-text { 
    display:none;
  }
   .records .detail .teaser-text { 
     margin-top: 0;
   }
   .mobile-no-split {
     width:100%;
     padding-right: 0;
   }
   // related 
   .related-content .related .column, .related .columns { padding-right: 10px }

   // concerts 
  .concerts .headerpic .headerpic-image {
    min-height: 400px;
  }
  .concerts .headerpic .headerpic-block h1, .concerts .headerpic .headerpic-block .text {
    font-size: 40px;
    line-height: 38px;
  }

  // avant-guardian
  .avant-guardian .big {
    line-height: 45px;
    width: 100%;
  }
  .avant-guardian .detail {
    .header-intro {
      margin: 0 auto;
      left:0;
    }
    .square {
      display: none;
    }
    h1::before {
      display: none;
    }
    h1 {
      font-size: 65px;
      line-height: 60px;
    }
    blockquote {
      padding-right: 0;
    }
    
     
  }
  h1 {
    font-size: 65px;
    line-height: 70px;
  }
  .move-to-left-on-mobile {
    float:left!important;
  }

}

/* iphone 5 only */

@media handheld, only screen and (max-width: 640px)  {
  /* masonry */
  .masonry-sizer,
  .masonry-item { width: 100% !important; padding-right: 20px; padding-left: 0px; }
  /* 4 columns */
  .masonry-sizer,
  .masonry-item { width: 50%; padding-right: 20px; padding-left: 0px;  }
  .masonry-item-double { width: 50%; padding-right: 20px; padding-left: 0px; }
  // av grid has bigger spacing between the columns
  .avant-guardian .masonry-sizer,
  .avant-guardian .masonry-item { width: 100%; padding-right: 20px; padding-left: 0px; }
  
  // av grid has bigger spacing between the columns
  .band-listing  .masonry-sizer,
  .band-listing  .masonry-item { width:100%; padding-right: 0px; padding-left: 0px; }
  
  .masonry-one-col-mobile .masonry-sizer,
  .masonry-one-col-mobile .masonry-item { width:100%; padding-right: 10px; padding-left: 10px; }

}

/* tablet view (orange) */
@media handheld, only screen and (min-width: 860px) and (max-width: 1080px) {
  .content {
    padding-top: 90px;
  }
  .brand-logo-svg {
    width: 60px;
    padding-top: 60px;
    background-size: 60px 60px;
    margin: -10px 0 0 0;
  }
}
@media handheld, only screen and (min-width: 1081px) and (max-width: 1180px) {
  .content {
    padding-top: 100px;
  }
}

/* ultru wide view */
@media handheld, only screen and (min-width: 1350px) {
  .content {
    padding-top: 115px;
  }
}
/* ultru ultra wide view */
@media handheld, only screen and (min-width: 1650px) {
  .content {
    padding-top: 150px;
  }
}

