// Outer navigation wrapper
.navigation {
  position: fixed;
  top: 0;
  left:0px;
  right:0;
  z-index:990;
  background: transparent;
  padding: 15px 0px 12px 7px;
  font-weight: 400;
  background:$white;
  font-family: $special-font-family;
  a {
    text-decoration: none;
  }
 /* max-height: 100px; */
}
// mobile
 .nav-list {
    display: none;
}

/* ------- COLOR EXCEPTIONS --------- */
.homepage .navigation, 
.concerts .navigation {
  background: $black;
  color: rgba(255,255,255,0)!important;
  nav:hover {
    color: $white;
  }
  a { color: $white !important; }
}
.concerts .navigation .overlay-filter {
  color: $cyan !important;
}
.records .navigation .overlay-filter {
  color: $magenta !important;
}
.avant-guardian .navigation .overlay-filter {
  color: $purple !important;
}

.concerts .navigation a:hover  {
  color: $cyan !important;
}
.records .navigation a:hover {
  color: $magenta !important;
}
.avant-guardian .navigation a:hover {
  color: $purple !important;
}
.homepage #nav-toggle span, .homepage #nav-toggle span:before, .homepage #nav-toggle  span:after,
.concerts #nav-toggle span, .concerts #nav-toggle span:before, .concerts #nav-toggle  span:after {
  background: $white;
}

.homepage #nav-toggle.active span,
.concerts #nav-toggle.active span {
   background: transparent; 
}

.records .navigation .overlay-filter-close,
.records .navigation .overlay-filter-close:hover, {
  color:$white !important;
  text-decoration: underline;
}
.concerts .navigation .overlay-filter-close,
.concerts .navigation .overlay-filter-close:hover, {
  color:$black !important;
  text-decoration: underline;
}
.avant-guardian .navigation .overlay-filter-close,
.avant-guardian .navigation .overlay-filter-close:hover, {
  color:$white !important;
  text-decoration: underline;
}
body.overlay-active.concerts .navigation a.active {
  color:$black !important;
}


/* -------  EXCEPTIONS WITH OVERLAY --------- */
body.overlay-active .navigation {  
  z-index:991;
  background: transparent!important;
  a, .brand-logo-svg, .sentence { opacity:0; }
  a.active { display:inline; opacity:1; }
  
}



.homepage .navigation {
  display: none;
}
// Logo and branding
.brand {
  position: absolute;
  padding-left: 0px;
  float: left;
  line-height: $nav-height;
  text-transform: uppercase;
  font-size: 1.4em;
  a,
  a:visited {
    color: $nav-font-color;
    text-decoration: none;
  }
}

// Navigation
nav {
  float: left;
  width: calc(100% - 80px);
  font-size: $nav-font-size;
  line-height: $nav-medium-line-height;
  color: rgba(0,0,0,0);
  &:hover {
    color: rgba(0,0,0,1);
  }
  // new way with invisible sentence
  .nav-list-sentence {
    width: 80%; 
    float:left;
    padding-left:3px;
    
    a,
    a:visited {
      color: $nav-font-color;
      //text-decoration: none;
      &:hover {
        color: $nav-link-hover-color;
        text-decoration: underline;
      }
    }
    a.active {
      color: $nav-link-hover-color;
      text-decoration: underline;
      position: relative;
      z-index:999;
    } 
  }

  .nav-list-browse-word {
    float:left;
   /* width: 140px; */
    margin-left: 0px;
  } 
  .overlay-filter {
    &:hover {
      text-decoration: underline;
    }
  }

  // mobile
  ul.nav-list {
    display: none;
    width: auto;
    padding: 0;
    margin: 0 auto;
    
      li {
        width: 100%;
        display:block;
        text-align: center;
        border-bottom:1px solid $black;
        float: left;
        a {
          padding: 5px 0;
          line-height: 20px;
        }
        a:hover {
          text-decoration: none;
        }
      } 
      li.noline {
        border-bottom:0px solid $black;
      }
 }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      text-align: right;
      font-size: $medium-font-size;
      line-height: 30px;
      padding: 3px 5px ;
      a,
      a:visited {
        
        color: $nav-font-color;
        text-decoration: none;
        &:hover {
          color: $nav-link-hover-color;
          text-decoration: underline;
        }
        &:not(:only-child):after {
          padding-left: 4px;
          content: ' ▾';
        }
      } // Dropdown list
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
      
    }
  }
}

// Dropdown list binds to JS toggle event
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  /* not needed background: $nav-background-color; */
  height: 60px;
  width: 60px;
}






/* RESPONSIVE ------------*/

 /* in settings: $nav-font-size: 32px;
$nav-font-size-tablet: 28px;
$nav-font-size-wide: 38px;

$nav-medium-line-height: 36px;
$nav-line-height-size-tablet: 36px;
$nav-line-height-size-wide: 36px; */  

@media only screen and (max-width: $nav-breakpoint) {
  .navigation {
    min-height: 80px;
    padding-bottom: 5px;
    .medium-12.columns {
      padding-right:0;
      padding-left:0;
      padding-bottom:10px;
    }
  }
  .concerts ul.nav-list li {
    border-color: $white;
  }
  .mobile-clearfix:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
  }

  // Hamburger nav visible on mobile only
  .nav-mobile {
     display: block; 
  }
 
  nav {
    font-size: 6.0vw;
    line-height: 6.2vw;
    width: calc(100% - 160px);
    margin-left: 85px;
    text-align: center;

    /* krakie weird layout */
    .nav-list-sentence {
      display: none;
    }
    .hide-on-mobile {
      display: none;
    }

    /* 

    /* default mob stuff */
    ul {
      display: none;
      li {
        float: left;
        padding: 0;
        a {
          padding: 5px 0;
          display: block;
          line-height: 20px;
        }
        a:hover {
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
  .brand-logo-svg {
    float: left;
    position: absolute;
    top: 0;
    left: 10px;
  }

  
}
/* iphone 5 */
@media handheld, only screen and (max-width: 860px) {
  nav ul li a, nav ul li a:visited {
    font-size: 18px;
  }
  
}
/* tablet view */
@media handheld, only screen and (min-width: 860px) and (max-width: 1080px) {
    nav {
      font-size: 2.4vw;
      line-height: 2.5vw;
    //  border: 1px solid orange;
    }
}
@media handheld, only screen and (min-width: 1081px) and (max-width: 1180px) {
  nav {
    font-size: 2.5vw;
    line-height: 2.6vw;
 //   border: 1px solid yellow;
  }
}

/* ultru wide view */
@media handheld, only screen and (min-width: 1350px) {
   nav {
      float: left;
      font-size: 2.6vw;
      line-height: 2.7vw;
      // border: 1px solid blue;
      
    }
}


@media screen and (min-width: $nav-breakpoint) {
  .nav-list {
    display: none;
  }
}


#nav-toggle {
  position: absolute;
  left: -18px;
  top: 5px;
  cursor: pointer;
  padding: 30px 55px 26px 10px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 35px;
    background: $black;  /* the stripes */
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
